import React from "react";
import logo from "../../assets/images/logo.png";

const Footer = () => {
  return (
    <div className=" text-[white] bg-[#000] flex ">
      <div className="container mx-auto">
        <div className=" grid grid-cols-4 gap-[100px] pt-[50px] pb-[30px]">
          <div>
            <img src={logo} alt="" className="w-[250px]" />
            <p className="text-[12px] text-[#fff]">
              The most visually recognizable Indian spice, this stuff is
              roaringly bright and vibrant, with a slightly bitter flavor.
            </p>
          </div>
          <div className="">
            <div className=" text-[18px] font-[600]">Company</div>
            <div className="flex flex-col gap-y-[8px] text-Medium+/Paragraph/Large mt-[16px]">
              <div className="text-[14px] font-[400]">About us</div>
              <div className="text-[14px] font-[400]">Team</div>
              <div className="text-[14px] font-[400]">Careers</div>
              <div className="text-[14px] font-[400]">Blog</div>
            </div>
          </div>
          <div className=" self-start">
            <div className="text-[18px] font-[600]">Contact</div>
            <div className="flex flex-col gap-y-[8px] text-Medium+/Paragraph/Large mt-[16px]">
              <div className="text-[14px] font-[400]">Help & Support</div>
              <div className="text-[14px] font-[400]">Partner with us</div>
              <div className="text-[14px] font-[400]">Ride with us</div>
            </div>
          </div>
          <div className=" self-start">
            <div className="text-[18px] font-[600]">Legal</div>
            <div className="flex flex-col gap-y-[8px] text-Medium+/Paragraph/Large mt-[16px]">
              <div className="text-[14px] font-[400]">Terms & Conditions</div>
              <div className="text-[14px] font-[400]">
                Refund & Cancellation
              </div>
              <div className="text-[14px] font-[400]">Privacy Policy</div>
              <div className="text-[14px] font-[400]">Cookie Policy</div>
            </div>
          </div>
        </div>
        <hr className="h-[1px] bg-[#333333] w-[100%]" />
        <div className="flex justify-between py-[20px]">
          <p className="text-[14px] text-[#fff]">Copyright 2023 - CurryCook</p>

          <div className="flex gap-x-[20px]"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
