import React, { useEffect, useState } from "react";
import Topbar from "../../components/Topbar/Topbar";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer";
import product1 from "../../assets/images/product1.png";
import cartItem from "../../assets/images/cartItem.png";
import deleteicon from "../../assets/icons/deleteIcon.svg";
import { CommonApi } from "../../apis/commonApi";
import { useParams, useSearchParams } from "react-router-dom";
import bagIcon from "../../assets/icons/bag.svg";

import ModalContainer from "../../components/ModalContainer/ModalContainer";
import { ToastContainer, toast } from "react-toastify";

const Products = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [categoryData, setCategoryData] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});

  const [productData, setProductData] = React.useState<any>([]);

  const [cartData, setCartData] = React.useState<any>([]);

  const getCategory = async () => {
    try {
      const response = await CommonApi.getCategoryList();

      if (response.success) {
        const arr = response.data.values.sort(function (a: any, b: any) {
          return a.sequence - b.sequence;
        });
        setCategoryData(arr);
        setSelectedCategory(response.data.values[0]);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
    }
  };

  const getProduct = async () => {
    try {
      const response = await CommonApi.getProductData();

      if (response.success) {
        setProductData(response.data.values);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
    }
  };

  useEffect(() => {
    getCategory();
    getProduct();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (searchParams.get("cartAdd") && productData.length > 0) {
      setCartData([
        ...cartData,
        productData.find(
          (item: any) => item.product_id == searchParams.get("cartAdd")
        ),
      ]);
    }
  }, [productData]);

  const [buildCurry, setBuildCurry] = React.useState<any>(false);

  const [curryData, setCurryData] = React.useState<any>([]);

  const [step, setStep] = React.useState<any>(1);

  useEffect(() => {
    setSelectedCategory(categoryData[step - 1]);
  }, [step]);

  const checkCurryCart = (step: any) => {
    let isCurry = false;
    cartData.forEach((item: any) => {
      if (item?.category_id == categoryData[step - 2]?.category_id) {
        isCurry = true;
      }
    });
    return isCurry;
  };

  const changeStep = (stepNew: number) => {
    if (buildCurry) {
      if (!checkCurryCart(stepNew) && stepNew > step && step < 5) {
        toast.error("Please add an Item First");
        return;
      }
      if (stepNew == 5) {
        setBuildCurryModalOpen(true);
      }
      setStep(stepNew);
    } else {
      setStep(stepNew);
    }
  };

  useEffect(() => {
    if (searchParams.get("buildCurry") == "true") {
      setBuildCurry(true);
    }
  }, [buildCurry]);

  const [buildCurryModalOpen, setBuildCurryModalOpen] =
    React.useState<any>(false);

  useEffect(() => {
    if (buildCurry && cartData) {
      if (cartData.length == 0) {
        return;
      }

      if (step == categoryData.length) {
        return;
      }

      // if (step == 1 && cartData.length == 0) {
      //   return;
      // }
      changeStep(step + 1);
    }
  }, [cartData]);

  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    if (buildCurry) {
      let sum = 0;

      Object.keys(curryData)?.map((item, index) => {
        sum += Number(
          curryData[item]
            ?.reduce(function (sum: any, tax: any) {
              return sum + tax?.product_price * tax?.quantity;
            }, 0)
            ?.toFixed(2)
        );
      });

      sum += Number(
        cartData
          ?.reduce(function (sum: any, tax: any) {
            return sum + tax?.product_price * tax?.quantity;
          }, 0)
          ?.toFixed(2)
      );
      setTotalSum(sum);
    }
  }, [cartData, curryData]);

  return (
    <div>
      <Topbar />
      <Navbar />
      <ToastContainer />

      <ModalContainer
        title="Order"
        modalOpen={buildCurryModalOpen}
        setModalOpen={setBuildCurryModalOpen}
        closeModal={() => console.log("close")}
        width={40}
      >
        <div className=" flex flex-col px-[30px]">
          <div className="flex gap-[10px] flex-wrap max-w-[500px] mx-auto ">
            <p
              className="text-[var(--primary)] border-[1px] border-[var(--primary)] px-[10px] rounded-[10px] cursor-pointer"
              onClick={() => {
                changeStep(1);
                if (cartData.length > 0) {
                  setCurryData([cartData, ...curryData]);
                  setCartData([]);
                }
                setBuildCurryModalOpen(false);
              }}
            >
              Pick Your Curry
            </p>
            <p
              className="text-[var(--primary)] border-[1px] border-[var(--primary)] px-[10px] rounded-[10px] cursor-pointer"
              onClick={() => {
                // setStep(5);
                setBuildCurryModalOpen(false);
                setCurryData([cartData, ...curryData]);
                setCartData([]);
              }}
            >
              Pick Appetizer
            </p>
            <p
              className="text-[var(--primary)] border-[1px] border-[var(--primary)] px-[10px] rounded-[10px] cursor-pointer"
              onClick={() => {
                changeStep(6);
                setBuildCurryModalOpen(false);
                setCurryData([cartData, ...curryData]);
                setCartData([]);
              }}
            >
              Pick Tandoori
            </p>
            <p
              className="text-[var(--primary)] border-[1px] border-[var(--primary)] px-[10px] rounded-[10px] cursor-pointer"
              onClick={() => {
                changeStep(7);
                setBuildCurryModalOpen(false);
                setCurryData([cartData, ...curryData]);
                setCartData([]);
              }}
            >
              Pick Extra & Sides
            </p>
            <p
              className="text-[var(--primary)] border-[1px] border-[var(--primary)] px-[10px] rounded-[10px] cursor-pointer"
              onClick={() => {
                changeStep(8);
                setBuildCurryModalOpen(false);
                setCurryData([cartData, ...curryData]);
                setCartData([]);
              }}
            >
              Pick Dessert
            </p>
            <p
              className="text-[var(--primary)] border-[1px] border-[var(--primary)] px-[10px] rounded-[10px] cursor-pointer"
              onClick={() => {
                changeStep(9);
                setBuildCurryModalOpen(false);
                setCurryData([cartData, ...curryData]);
                setCartData([]);
              }}
            >
              Pick Drink & Shake
            </p>
          </div>
          <div className="flex gap-[20px] mx-auto mt-[30px]">
            <button
              className="border-0 outline-0 b  rounded-[50px] bg-[#228b22] py-[10px] px-[30px] text-white font-600 w-[100%] mt-[10px] flex items-center gap-[10px] justify-center"
              onClick={() => {
                setBuildCurryModalOpen(false);
                setCurryData([...curryData, cartData]);
                setCartData([]);
              }}
            >
              <img src={bagIcon} className="h-[20px]" /> Checkout
            </button>
          </div>
        </div>
      </ModalContainer>

      <section>
        <div className="container mx-auto bg-productBanner  bg-cover rounded-[20px] overflow-hidden flex justify-center items-center py-[140px] mt-[50px]">
          <div className="content  flex items-center flex-col">
            <h2 className="text-white font-semibold text-[40px]">
              Build Your Own Curry
            </h2>
          </div>
        </div>

        <div className="container mx-auto mt-[120px] mb-[120px] flex flex-col items-center justify-center">
          <div className="grid grid-cols-4">
            <div className="col-span-3">
              <h2 className="text-[40px] text-center font-[600]">
                {selectedCategory?.category_title}
              </h2>
              <p className="font-[400] text-center text-[#636363] h-[10px] mt-[10px]">
                {selectedCategory?.category_desc}
              </p>
            </div>
            <div></div>
          </div>

          <div className="grid grid-cols-4 gap-[35px] mt-[50px] ">
            <div className="col-span-3">
              <div className="flex gap-[34px] overflow-auto">
                {categoryData.map((item: any, index: number) => (
                  <p
                    className={
                      "text-[18px]  font-[500] whitespace-nowrap cursor-pointer " +
                      (selectedCategory?.category_id == item?.category_id
                        ? "text-[var(--primary)]"
                        : "")
                    }
                    onClick={() => {
                      !buildCurry && changeStep(index + 1);
                    }}
                  >
                    {item.category_name}
                  </p>
                ))}
              </div>

              <div className="mt-[20px] grid grid-cols-3 gap-[30px]">
                {[
                  ...productData?.filter(
                    (item: any) =>
                      item.category_id == selectedCategory?.category_id
                  ),
                ].map((item, i) => {
                  return (
                    <div className="bg-[#fff] col-span-1 border-[1px] border-[#D3D4D6] flex flex-col items-center justify-center shadow-md rounded-[20px] p-[20px]">
                      <div className="rounded-[10px] w-[100%] max-w-[250px] overflow-hidden flex h-[162px]">
                        <img
                          src={item?.product_photo}
                          alt=""
                          className="object-cover w-[100%]  "
                        />
                      </div>
                      <h3 className="text-[20px] mt-[10px] font-semibold">
                        {item?.product_name}
                      </h3>
                      {/* <p className="mt-[5px] text-[#636363] text-[14px] mb-[10px]">
                        {item?.product_desc}
                      </p> */}
                      <div className="mt-[auto] flex items-end gap-[10px] mb-[10px]">
                        {/* <p className="text-[14px] font-[500] relative text-[#636363]">
                          ${item?.product_price}
                          <div className="h-[1px] bg-[#636363] absolute w-[100%] top-[50%]"></div>
                        </p> */}
                        <p className="text-[20px] text-[var(--primary)]">
                          {Number(item?.product_price - item?.discount) > 0 ? (
                            <>
                              $
                              {Number(
                                item?.product_price - item?.discount
                              )?.toFixed(2)}
                            </>
                          ) : (
                            <>FREE</>
                          )}
                        </p>
                      </div>
                      <button
                        className="border-0 outline-0  rounded-[50px] bg-[var(--primary)] py-[10px] px-[30px] text-white font-600 w-[100%] mt-[10px]"
                        onClick={async () => {
                          const cartItem = cartData.find(
                            (cartItem: any) =>
                              cartItem.product_id == item.product_id
                          );
                          if (cartItem) {
                            let newCartData = cartData.map((cartItem: any) => {
                              if (cartItem.product_id == item.product_id) {
                                return {
                                  ...cartItem,
                                  quantity: cartItem.quantity + 1,
                                };
                              }
                              return cartItem;
                            });
                            setCartData(newCartData);

                            return;
                          }
                          const newCartData = [
                            ...cartData,
                            { ...item, quantity: 1 },
                          ];
                          await setCartData(newCartData);
                        }}
                      >
                        {cartData.find(
                          (cartItem: any) =>
                            cartItem.product_id == item.product_id
                        )
                          ? "+ Add More"
                          : "Add to Cart"}
                      </button>
                    </div>
                  );
                })}
              </div>

              <div className="mt-[30px]">
                <div className="flex items-center justify-between">
                  {categoryData[step - 2]?.category_title ? (
                    <p
                      className="text-[var(--primary)] cursor-pointer"
                      onClick={() => changeStep(step - 1)}
                    >
                      &lt; {categoryData[step - 2]?.category_title}
                    </p>
                  ) : (
                    <p></p>
                  )}

                  {categoryData[step]?.category_title ? (
                    <p
                      className="text-[var(--primary)] cursor-pointer"
                      onClick={() => changeStep(step + 1)}
                    >
                      {categoryData[step]?.category_title} &gt;
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-1 flex flex-col bg-[var(--secondary)] p-[20px] rounded-[20px]">
              <div className="flex flex-col  max-h-[550px] overflow-y-scroll">
                {buildCurry &&
                  Object.keys(curryData).length > 0 &&
                  Object.keys(curryData).map((item: any, i: any) => (
                    <>
                      <p className="mb-[10px] text-[var(--primary)] flex justify-between items-center">
                        {" "}
                        Curry Plate {Number(item) + 1}
                        <div className="w-[30px] h-[30px] rounded-[50%] flex items-center justify-center bg-[#fff] ml-auto">
                          <img
                            src={deleteicon}
                            className="w-[18px] cursor-pointer"
                            alt=""
                            onClick={() => {
                              let newCurryData = [...curryData];
                              newCurryData.splice(item, 1);
                              setCurryData(newCurryData);
                            }}
                          />
                        </div>
                      </p>
                      {curryData[item].map((item: any, i: any) => (
                        <>
                          <div className="border-b-[2px] border-gray-200 py-[15px]">
                            <div className="flex   items-center justify-start">
                              <img
                                src={item?.product_photo}
                                className="rounded-[10px] w-[50px] h-[50px]"
                                alt=""
                              />
                              <div className="ml-[10px] relative ">
                                <h2 className="font-[500] flex gap-[6px] items-center">
                                  {item?.product_name}
                                  <p className="text-[var(--primary)] text-[12px] whitespace-nowrap">
                                    x {item?.quantity}
                                  </p>
                                </h2>
                                <p className="text-[14px] font-[500] text-[var(--primary)]">
                                  $
                                  {(
                                    Number(item?.product_price) * item?.quantity
                                  )?.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  ))}

                {cartData.map((item: any, i: any) => (
                  <div className="border-b-[2px] border-gray-200 py-[15px]">
                    {buildCurry && (
                      <p className="mb-[10px] text-[var(--primary)] flex justify-between items-cente">
                        {
                          categoryData?.find(
                            (itemCat: any) =>
                              itemCat?.category_id == item?.category_id
                          )?.category_name
                        }
                      </p>
                    )}
                    <div className="flex  gap-[10px] items-center justify-center">
                      <img
                        src={item?.product_photo}
                        className="rounded-[10px] w-[50px] h-[50px]"
                        alt=""
                      />
                      <div className="ml-[10px] relative ">
                        <h2 className="font-[500] flex gap-[6px] items-center">
                          {item?.product_name}
                          <p className="text-[var(--primary)] text-[12px] whitespace-nowrap">
                            x {item?.quantity}
                          </p>
                        </h2>
                        <p className="text-[14px] font-[500] text-[var(--primary)]">
                          $
                          {(
                            Number(item?.product_price) * item?.quantity
                          )?.toFixed(2)}
                        </p>
                      </div>
                      <div className="w-[30px] h-[30px] rounded-[50%] flex items-center justify-center bg-[#fff] ml-auto">
                        <img
                          src={deleteicon}
                          className="w-[18px] cursor-pointer"
                          alt=""
                          onClick={() => {
                            const newCartData = [...cartData];

                            if (cartData[i].quantity > 1) {
                              newCartData[i].quantity =
                                cartData[i].quantity - 1;
                            } else {
                              newCartData.splice(i, 1);
                            }
                            // newCartData.splice(i, 1);
                            setCartData(newCartData);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-[10px] items-center justify-center mt-auto">
                {buildCurry ? (
                  <p className="text-[20px] text-[var(--primary)]">
                    ${totalSum?.toFixed(2)}
                  </p>
                ) : (
                  <p className="text-[20px] text-[var(--primary)]">
                    $
                    {cartData
                      ?.reduce(function (sum: any, tax: any) {
                        return sum + tax?.product_price * tax?.quantity;
                      }, 0)
                      ?.toFixed(2)}
                  </p>
                )}
                <button className="border-0 outline-0 b  rounded-[50px] bg-[#228b22] py-[10px] px-[30px] text-white font-600 w-[100%] mt-[10px] flex items-center gap-[10px] justify-center">
                  <img src={bagIcon} className="h-[20px]" /> Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Products;
