import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const Topbar = () => {

  const navigate = useNavigate();

  return (
    <div className="bg-[var(--secondary)] border-b-[1px]  shadow-lg flex border-bottom-[1px]">
      <div className="container flex mx-auto justify-between py-[15px]">
        <div className="flex" onClick={() => navigate("/")}>
          <img src={logo} alt="" className="w-[220px] cursor-pointer" onClick={() => {
            navigate("/")
          }} />
        </div>
        <div className="flex gap-[30px] ml-auto">
          <div className="flex items-center cursor-pointer gap-[10px]" onClick={() => {
            navigate("/product?buildCurry=true")
          }}>
            <p>Build Curry</p>
          </div>
          <div className="flex cursor-pointer items-center gap-[10px]">
            <p>Build Pizza</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
