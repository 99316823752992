import React from "react";
import bag from "../../assets/icons/bag.svg";

import user from "../../assets/icons/User.svg";
import search from "../../assets/icons/search.svg";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[var(--primary)] py-[15px]">
      <div className="container mx-auto flex justify-between ">
        <div className="flex gap-[20px]">
          <p
            className="text-white text-[14px] font-medium cursor-pointer"
            onClick={() => navigate("/product")}
          >
            Order menu
          </p>
          <p className="text-white text-[14px] font-medium">What we value</p>
          <p className="text-white text-[14px] font-medium">who we are</p>
          <p className="text-white text-[14px] font-medium">Jobs</p>
        </div>

        <div className="flex gap-[20px]">
          <div className="relative">
            <img
              src={search}
              alt=""
              className="h-[16px] absolute top-[3px] left-[3px]"
            />
            <input
              className="border-b-[1px] pl-[30px] border-white outline-0 placeholder-white pb-[5px] text-white bg-transparent"
              placeholder="Search dishes"
            />
          </div>

          <img src={bag} alt="" className="h-[20px]" />
          <img src={user} alt="" className="h-[20px]" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
